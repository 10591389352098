
import { useState,useContext} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { addFilterAction } from "../../store/filterList";

import {Button,InputLabel,MenuItem,FormControl,Select} from '@mui/material';

import { GlobalContext } from "../../auth/GlobalProvider";
import { useTranslation } from 'react-i18next';

const Status = () =>{

    const {t} = useTranslation();

    const status=t('filter.status',{returnObjects:true});

    console.log(status);

    // redux
    const dispatch = useDispatch();
    const {filter} = useSelector(state => state.filter);
    const def = filter.filter(item => item.type === 5)?.[0]?.value || [];

    // context
    const {show} = useContext(GlobalContext);

    // default
    // const val = useSelector(state => state.filter.filter.filter(item => item.type === 4).value);
    const [value,setValue] = useState(def);

    const setFilter = () => {
        if(value.length){
            const filterStr=[t('filter.st'),status.filter((item,key)=>value.includes(key)).join(', ')];
            const filter = {type:5,name:filterStr,value:value};
            dispatch(addFilterAction(filter));
        }else{
           // clear type 5 
        }
        show(state=>!state);
    }

    return (
        <div className="filter-form">
            <div>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{t('filter.st')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        displayEmpty
                        multiple={true}
                        label={t('filter.st')}
                        onChange={e=>setValue(e.target.value)}
                    >
                        {status.map((item,key)=>
                            <MenuItem value={key} key={key}>{item}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Button size="large" variant="contained" sx={{ width: 1 }} onClick={()=>setFilter()}>{t('filter.use')}</Button>
            </div>
        </div>
    )
}

export {Status}