import "./Footer.scss";
const Footer = () =>{
    return (
        <div className="footer">
            <div className="block-small">
                <div className="_center">
                    &copy; <a href="https://webmaker.studio" target="_blank">Webmaker Studio</a>
                </div>
            </div>
        </div>
    )
}

export {Footer}