import {createStore,combineReducers} from "redux";

import {filterList} from "./filterList";
import {hotelList} from "./hotelList";

const reducer = combineReducers({
    filter:filterList,
    hotel:hotelList
})

export const store = createStore(reducer);