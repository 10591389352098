import "./Content.scss";

import {Outlet} from 'react-router-dom';
import {Top} from './Top';
import {Footer} from './Footer';

import ScrollToTop from "../components/ScrollToTop";
//import { ModalCart } from '../components/ModalCart';
//import { Telegram } from '../components/Telegram';

const Content = () =>{

    return (
        <>  
            {/* <ModalCart/> */}
            <ScrollToTop/>
            <Top/>
            <div className="content overflow-none">
                <div className="block-small">
                    <div className="content-block">
                        <Outlet/>
                    </div>
                </div>
            </div>
            <Footer/>
            {/* <Telegram/> */}
        </>
    )
}

export {Content}