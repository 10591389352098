import "./Status.scss";

const Status = ({status}) =>{

    return (
        <div className={`status ${status.type}`}>
            {status.name}
        </div>
    )
}

export {Status}