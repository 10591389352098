import { useState } from "react";
import { BronDet } from "../../components/BronDet";

const STATUS = [
    '_del',
    '_temp',
    '_rezerv',
    '_book'
]

const HotelBook = ({book}) =>{

    const [show, setShow] = useState(false);

    const width = () =>{
        let width = book.days * 61 + (book.after ? 30 : 0) + (book.before ? 30 : 0);
        return `${width}px`;
    }

    return <>
        <div 
            className={`hotel-book ${book.before?'_before':''} ${book.after?'_after':''} ${book.reverse?'_reverse':''}`} 
            style={{"width":width()}}
            onClick={()=>setShow(true)}
        >
            <div className={`hotel-book-info ${STATUS[book.status]}`}>
                <div className={`hotel-book-price ${book.balance.color}`}>€{book.balance.value}</div>
                <div className="hotel-book-type">{book.code}</div>
            </div>
        </div>
        {show?<BronDet setShow={setShow} id={book.id}/>:''}
    </>
}

export {HotelBook}