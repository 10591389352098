import { Account } from "./app/Account";
import { RequireAuth } from "./auth/RequireAuth";
import { Enter } from "./components/Enter";
import { useLogin } from "./hook/useLogin";

import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {

  const {user} = useLogin();

  let theme = createTheme({
    palette: {
      primary: {
        main: '#14ADB1',
        contrastText: '#ffffff',
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <RequireAuth>
        {user?<Account/>:<Enter/>}
      </RequireAuth>
    </ThemeProvider>
  );
}

export default App;
