import { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import "moment/locale/ru.js";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { addFilterAction } from "../../store/filterList";

import { GlobalContext } from "../../auth/GlobalProvider";

import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const BookDate = () => {

  const {t,i18n} = useTranslation();
  const lang = i18n.language;
  const setLang = lang === 'ru' ? 'ru' : 'en';

  const sx = { width: "100%" };

  const [data, setValue] = useState({
    from: moment(new Date()),
    to: moment(new Date())
  });

  // context
  const { show } = useContext(GlobalContext);

  // dispatch
  const dispatch = useDispatch();

  const addFilter = () => {

    var dateFrom = new Date(data.from);
    var dateTo = new Date(data.to);

    // swap
    if (dateFrom > dateTo) [dateTo, dateFrom] = [dateFrom, dateTo];

    const to = dateTo.toLocaleDateString("ru-Ru"); // , {timeZone: "Europe/Kiev" }
    const from = dateFrom.toLocaleDateString("ru-Ru"); // , {timeZone: "Europe/Kiev"}

    const equal = dateFrom.getTime() === dateTo.getTime();

    // add filter
    const d = equal ? from : `${from} - ${to}`;
    const dd = equal ? [from] : [from, to];

    const filter = { type: 1, name: [t('filter.dz'), d], value: dd };
    dispatch(addFilterAction(filter));

    // close
    show((state) => !state);
  };

  return (
    <div className="filter-form">
      {/* <div>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Фильтр</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Фильтр"
                    ref={sel}
                    onChange={e=>setType(e.target.value)}
                >
                    <MenuItem value={0}>Весь период</MenuItem>
                    <MenuItem value={1}>Период</MenuItem>
                </Select>
            </FormControl>
        </div> */}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={setLang}>
        <div>
          <DatePicker
            views={["year", "month", "day"]}
            label={t('filter.pz')}
            value={data.from}
            sx={sx}
            onChange={(value) => setValue({ ...data, from: value })}
          />
        </div>
        <div>
          <DatePicker
            views={["year", "month", "day"]}
            label={t('filter.pd')}
            value={data.to}
            sx={sx}
            onChange={(value) => setValue({ ...data, to: value })}
          />

          {/* <SingleInputDateRangeField label="Период" sx={sx}/> */}
        </div>
      </LocalizationProvider>
      <div>
        <Button
          size="large"
          variant="contained"
          sx={{ width: 1 }}
          onClick={() => addFilter()}
        >
          {t('filter.use')}
        </Button>
      </div>
    </div>
  );
};

export { BookDate };
