// left cart modal
//const action = {type:''}

//localStorage.removeItem("like");

const oneDay = 1000 * 60 * 60 * 24;

// local list
const hotel = localStorage.getItem("hotel") !== null 
  ? JSON.parse(localStorage.getItem("hotel"))
  : {
    data:{
        from: new Date(Date.now() - 1*oneDay),
        to: new Date(Date.now() + 30*oneDay),
    },
    category:[]
  };

const defState={
    hotel:hotel
  }
  
  const SET_FILTER = "SET_FILTER";
  
  export const hotelList = (state = defState,action) => {
    switch(action.type){
      // add
      case SET_FILTER:
        var newFilter = [...state.hotel,action.filter];

        localStorage.setItem("hotel",JSON.stringify({...hotel}));
        return {...state,hotel:newFilter}

      default:
        return state
    }
  
  }
  
  export const setFilterAction = (filter) => ({type:SET_FILTER,filter:filter});