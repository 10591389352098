import { TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import { addFilterAction } from "../../store/filterList";

import {Loading} from "../../components/Loading";

import { GlobalContext } from "../../auth/GlobalProvider";

const ClientSearch = ({t}) => {
  const [search, setSearch] = useState("");
  const [res, setResult] = useState([]);

  // fetch
  const { user } = useLogin();
  const axios = useAxios(user);

  // redux
  const dispatch = useDispatch();
  const ids = useSelector(state => state.filter.filter.filter(item => item.type === 3 && item.user === 0).map(item=>item.value));

  // context
  const {show} = useContext(GlobalContext);

  // time out
  var timeOut = null;

  // search city
  useEffect(() => {
    if (search.length >= 3) {
      setResult(null);
      const req = {
        search: search,
        ids: ids
      };
      timeOut = setTimeout(() => {
        axios.get("/admin/client", { params: req }).then(({ data }) => {
          setResult(data.res);
        });
      }, 1000);
    }
    return () => {
      if (typeof timeOut === "number") {
        clearTimeout(timeOut);
      }
    };
  }, [search]);

  const setFilter = (item) =>{
    // append filter
    const filter = {type:3,user:0,name:[t('filter.client'),item.name],value:item.id};
    dispatch(addFilterAction(filter));
    //close modal
    show(state=>!state);
  } 

  return (
    <div className="filter-client">
      <div>
        <TextField
          id="outlined-basic"
          label={t('filter.data')}
          variant="outlined"
          sx={{ width: 1 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={() => setResult([])}
        />
      </div>
      {res?res.length? (
        <div className="filter-client-res">
          {res.map((item, key) => (
            <div onClick={() => setFilter(item)} key={key}>
              {item.name}
            </div>
          ))}
        </div>
      ) : 
        "":<Loading/>
      }
    </div>
  );
};

export { ClientSearch };
