import React,{ useState,useEffect } from "react";
import "./Hotel.scss";

import "moment/locale/ru";

import useAxios from  "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

import ScrollContainer from "react-indiana-drag-scroll";

import { HotelApart } from "./Hotel/HotelApart";

import { GlobalContext } from "../../auth/GlobalProvider";

import { Modal } from "../../components/Modal";
import { HotelType } from "./Hotel/HotelType";
import { HotelData } from "./Hotel/HotelData";
import { useTranslation } from "react-i18next";

let today = new Date();
let year = today.getFullYear();
let month = today.getMonth();
const weekend = [0,6];
const oneDay = 1000 * 60 * 60 * 24;
const week = ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'];

const getDatesBetween = ({from,to}) => {
    let dates = [];
    let currentDate = new Date(from);
    while (currentDate <= to) {
        dates.push(new Date(currentDate));
        let newData = currentDate.getDate() + 1;
        currentDate.setDate(newData);
    }
    return dates;
};

const toTitleFormat = (date) =>{
    let d = date.getDate();
    let m = ('0'+(date.getMonth()+1)).slice(-2)
    return `${d}.${m}`;
}

const Hotel = () =>{

    const { t } = useTranslation();

    const {user} = useLogin();
    const axios=useAxios(user);

    // response
    const [data,setData] = useState(null);

    // data diapazone
    const [dates,setDates] = useState(null);

    // hide type
    const hideType = (type) =>{
        data.apart = data.apart.map(item => item.id === type ? {...item,hide:!item.hide} :item);
        //console.log(data);
        setData({...data});
    }

    const [search,setSearch] = useState({
        data:{
            from: new Date(Date.now() - 1*oneDay),
            to: new Date(Date.now() + 30*oneDay),
        },
        type:[]
    })

    // on search change
    useEffect(function(){
        setDates(getDatesBetween(search.data));
        axios.get('/admin/hotel',{params:search})
        .then(({data})=>{
            // add hide prop
            data.res.apart = data.res.apart.map(item => ({...item,hide:false}));
            setData(data.res)
        })
    },[search]);

    return (
        data?<div className="content-table">
            <div className="title">{t('hotel.title')}</div>

            <div className="hotel-filter">
                <div className="hotel-filter-left">
                    <FilterData search={search} setSearch={setSearch} label={`${toTitleFormat(search.data.from)}-${toTitleFormat(search.data.to)}`}/>
                    <FilterType search={search} setSearch={setSearch}/>
                </div>
                <div className="hotel-days">{data.days} {t('hotel.days')} / {data.book.length} {t('hotel.book')}</div>
            </div>
            
            <div className="hotel-block">
                <div className="hotel-first">H</div>
                <ScrollContainer vertical={true} horizontal={true} className="hotel-scroll">
                        <div className="hotel-top">
                            {
                                dates.map((day,key)=>{
                                    let dayNum = day.getDay();
                                    return <div key={key} className={weekend.includes(dayNum) ? '_weekend' : ''}>
                                        <div className="hotel-data">
                                            <div>{toTitleFormat(day)}</div>
                                            <div className="hotel-day">{week[dayNum]}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="hotel-left">
                            {
                                data.apart.map((apart,key)=>(
                                    <React.Fragment key={key}>
                                        <div className="hotel-left-type" onClick={()=>hideType(apart.id)}>{apart.name}</div>
                                        <div className={`hotel-left-block ${apart.hide?'_hide':''}`}>
                                            {apart.number.map((number,key)=>(
                                                <div key={key} className={`hotel-left-col ${number.closed?`_close`:''}`}>
                                                    <div>{number.name}</div>
                                                    <div className="hotel-type">{apart.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </div>
                        <div className="hotel-main" style={{width:data.days * 61}}>
                            <HotelApart data={data} dates={dates} hideType={hideType}/>
                        </div>
                </ScrollContainer>
            </div>
        </div>:null
    )
}

const FilterData = ({label,setSearch,search}) =>{

    const [show, setShow] = useState(false);

    return (
        <>
            {show?<Modal show={setShow}>
            <div>
                <GlobalContext.Provider value={{show:setShow}}>
                    <HotelData setSearch={setSearch} search={search}/>
                </GlobalContext.Provider>
            </div>
            </Modal>:null}
            <div>
                <div onClick={()=>setShow(true)}>
                    <div className="filter-item">{label}</div>
                </div>
            </div>
        </>
    )
}

const FilterType = ({setSearch,search}) =>{

    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    return (
        <>
            {show?<Modal show={setShow}>
            <div>
                <GlobalContext.Provider value={{show:setShow}}>
                    <HotelType setSearch={setSearch} search={search}/>
                </GlobalContext.Provider>
            </div>
            </Modal>:null}
            <div>
                <div onClick={()=>setShow(true)}>
                    <div className="filter-item">{t('hotel.cat')}</div>
                </div>
            </div>
        </>
    )
}

export {Hotel}

