import "../Page.scss";

import { useSelector } from "react-redux";
import { useEffect, useState, useTransition } from "react";
import ScrollContainer from "react-indiana-drag-scroll"

import { BronDet } from "../../components/BronDet";
import { Filter } from "../../components/Filter";

import useAxios from  "../../hook/useAxios";

import { Loading } from "../../components/Loading";
import { useLogin } from "../../hook/useLogin";
import { Message } from "../../components/Message";
import { useTranslation } from "react-i18next";

const Load = () =>{

    const {t} = useTranslation();

    const [data,setData] = useState(null);

    const {user} = useLogin();
    const axios=useAxios(user);

    const filter = useSelector(state => state.filter);

    useEffect(()=>{
        axios.get('/admin/load',{params:filter})
        .then(({data})=>setData(data.res))
    },[filter]);

    return (
        <div className="content-table">
            <div className="title">{t('load.title')}</div>
            <div><Filter/></div>

            {data
            ?data.list?
                <div className="content-info">

                    <div className="page-graf-block">
                        <div className="page-graf">
                            <div>{data.total.res}</div>
                            <div>{t('load.all_load')}</div>
                        </div>
                    </div>

                    <ScrollContainer vertical={true} horizontal={true} className="content-res">
                        <table width="100%" className="table">
                            <thead>
                                <tr>
                                    {t('load.table',{returnObjects:true}).map((v,i)=>
                                        <th key={i}>{v}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {data.list.map((item,key)=><Item key={key} item={item}/>)}
                            </tbody>
                            <tfoot className="table-total">
                                <tr>
                                    <td>{t('load.all_day')} {data.days} {t('load.days')}</td>
                                    <td>{data.total.all_apart}</td>
                                    <td>{data.total.all_apartday}/{data.total.all_bookday}</td>
                                    <td>{data.total.res}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </ScrollContainer>

                </div>
                :<Message mes={t('load.nores')}/>
            :<Loading/>}

            
        </div>
    )
}

const Item = ({item}) =>{

    const [show, setShow] = useState(false);

    return (
        <>
            <tr className="table-row">
                <td>{item.code}</td>
                <td>{item.apart}</td>
                <td>{item.apart_days}/{item.book_days}</td>
                <td>{item.res}</td>
            </tr>
            {show?<BronDet setShow={setShow} id={1}/>:''}
        </>
    )
    
}

export {Load}