import "../Page.scss";

import { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useSelector } from "react-redux";

import { BronDet } from "../../components/BronDet";
import { Filter } from "../../components/Filter";
import { Loading } from "../../components/Loading";
import { Message } from "../../components/Message";

import useAxios from "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";
import { useTranslation } from "react-i18next";

const Pay = () =>{

    const {t} = useTranslation();

    const [data,setData] = useState(null);

    const {user} = useLogin();
    const axios=useAxios(user);

    const filter = useSelector(state => state.filter);

    useEffect(()=>{
        axios.get('/admin/pay',{params:filter})
        .then(({data})=>setData(data.res))
    },[filter]);

    return (
        <div className="content-table">
            <div className="title">{t('pay.title')}</div>
            <div><Filter/></div>

            {data
            ?data.list?
                <div className="content-info">

                    <div className="page-graf-block">
                        <div className="page-graf">
                            <div>€ {data.total.all_res}</div>
                            <div>{t('pay.else')}</div>
                        </div>
                    </div>

                    <ScrollContainer vertical={true} horizontal={true} className="content-res">
                        <table width="100%" className="table">
                            <thead>
                                <tr>
                                    {t('pay.table',{returnObjects:true}).map((v,i)=>
                                        <th key={i}>{v}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {data.list.map((item,key)=><Item key={key} item={item}/>)}
                            </tbody>
                            <tfoot className="table-total">
                                <tr>
                                    <td colSpan={2}>{t('pay.all')}</td>
                                    <td>€ {data.total.all_pay}</td>
                                    <td>€ {data.total.all_res}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </ScrollContainer>

                </div>
                :<Message mes={t('pay.nores')}/>
            :<Loading/>}
        </div>
    )
}

const Item = ({item}) =>{

    const [show, setShow] = useState(false);

    return (
        <>
            <tr className="table-row">
                <td>{item.user}</td>
                <td>
                    <div className={`href _inline page-shifr ${item.avia?'_avia':''}`} onClick={e=>{e.stopPropagation();setShow(true)}}>
                        {item.shifr}
                    </div>
                    {/* <div className="page-status">{item.status}</div> */}
                </td>
                <td>€ {item.pay}</td>
                <td>€ {item.res}</td>
            </tr>
            {show?<BronDet setShow={setShow} id={item.id}/>:''}
        </>
    )
    
}

export {Pay}