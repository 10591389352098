import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
          translation: {
            menu:[
              {"url": "/", "name": "Bookings"},
              {"url": "/fin", "name": "Finances"},
              {"url": "/pay", "name": "Payments"},
              {"url": "/load", "name": "Occupancy"},
              {"url": "/hotel", "name": "Hotel"},
              {"url": "/stat", "name": "Statistics"}
            ],
            "book": {
              "title": "Bookings",
              "bc": "Bookings",
              "client": "Client",
              "source": "Source",
              "shifr": "Code",
              "night": "Nights",
              "guest": "Guests",
              "all": "Total",
              "nores": "No results found",
              "create": "Created"
          },
          "fin": {
              "title": "Finances",
              "pay": "Cost",
              "client": ["Client", "Code", "Amount"],
              "nores": "No results found"
          },
          "hotel": {
              "title": "Hotel",
              "days": "days",
              "book": "bookings",
              "cat": "Category"
          },
          "det": {
              "book" : "Booking",
              "title": "Order Information",
              "profit": "Balance",
              "avia": "Flight",
              "live": "Accommodation",
              "serv": "Services",
              "all": "Total",
              "dis": "Discount",
              "topay": "To pay",
              "payed": "Paid",
              "ret": "Return",
              "total": "Balance",
              "room": "Room",
              "dz": "Check-in date",
              "dv": "Check-out date",
              "night": "Nights",
              "source": "Source",
              "reis": "Number",
              "da": "Arrival date",
              "dd": "Return date",
              "road": "Route",
              "serv": "Services",
              "adult": "Adults",
              "name": "Name",
              "age": "Age",
              "pas": "Passport",
              "country": "Country",
              "data": "Date",
              "child": "Children",
              "client": "Client",
              "phone": "Phone",
              "dreg": "Registration date",
              "was": "Was online",
              "type": "Type",
              "ip": "IP Country"
            },
            "load": {
                "title": "Load",
                "all_load": "Total load",
                "table": ["Category", "Rooms", "N/D Days", "Load"],
                "all_day": "Total",
                "days": "days",
                "nores": "No results found"
            },
            "pay": {
              "title": "Payments",
              "else": "Remaining payments",
              "table": ["Client", "Code", "Paid", "Remaining"],
              "all": "Total",
              "nores": "No results found"
            },
            "filter": {
              "dz": "Check-in date",
              "dc": "Creation date",
              "pz": "Period from",
              "pd": "Period to",
              "use": "Apply",
              "cat": "Categories",
              "c": "Category",
              "f": "Filter",
              "search": "Search",
              "group": "Group",
              "client": "Client",
              "data": "Data",
              "do": "Order date",
              "status": ["new", "temporary reserve", "reserve", "booking"],
              "st": "Status"
            },
            "stat": {
              "title": "Order Sources",
              "source": ["Source", "Percentage", "Quantity"],
              "country": "Countries",
              "th": ["Country", "Percentage", "Quantity"]
            }
          }
        },
        ru: {
          translation: {
            menu:[
              {url:'/',name:'Бронировки'},
              {url:'/fin',name:'Финансы'},
              {url:'/pay',name:'Оплаты'},
              {url:'/load',name:'Загрузки'},
              {url:'/hotel',name:'Отель'},
              {url:'/stat',name:'Статистика'}
            ],
            book:{
              title: "Бронировки",
              bc:"Бронировок",
              client: "Заказчик",
              source: "Источник",
              shifr: "Шифр",
              night: "Ночей",
              guest: "Гостей",
              all : "Всего",
              nores: "Поиск не дал результата",
              create : "Создано",
            },
            fin:{
              title: "Финансы",
              pay : "Стоимость",
              client: ['Заказчик','Шифр','Сумма'],
              nores: "Поиск не дал результата"
            },
            hotel:{
              title: "Отель",
              days: "дней",
              book:"броней",
              cat: "Категория"
            },
            det:{
              book : "Бронирование",
              title: "Информация о заказе",
              profit: "Баланс",
              avia: "Авиаперелет",
              live: "Проживание",
              serv: "Услуги",
              all : "Всего",
              dis: "Скидка",
              topay: "К оплате",
              payed: "Оплачено",
              ret: "Возврат",
              total: "Баланс",
              room: "Номер",
              dz: "Дата заезда",
              dv:"Дата выезда",
              night: "Ночей",
              source: "Источник",
              reis:"Номер",
              da:"Дата прилета",
              dd: "Дата возврата",
              road:"Маршрут",
              serv: "Услуги",
              adult: "Взрослые",
              name:"Имя",
              age:"Возраст",
              pas: "Паспорт",
              country:"Страна",
              data:"Дата",
              child: "Дети",
              client: "Заказчик",
              phone: "Телефон",
              dreg: "Дата регис.",
              was: "Был на сайте",
              type: "Тип",
              ip: "Страна по IP"
            },
            load:{
              title: "Загрузка",
              all_load: "Общая загрузка",
              table:['Категория','Номеров','Дни Н/Б','Загрузка'],
              all_day:"Всего",
              days:"дней",
              nores:"Поиск не дал результата"
            },
            pay:{
              title: "Оплаты",
              else:"Остаток оплат",
              table:['Заказчик','Шифр','Оплачено','Остаток'],
              all:"Всего",
              nores:"Поиск не дал результата"
            },
            filter:{
              dz:"Дата заезда",
              dc:'Дата создание',
              pz : "Период c",
              pd:"Период до",
              use:"Применить",
              cat: "Категории",
              c : "Категория",
              f: "Фильтр",
              search :"Поиск",
              group:"Группа",
              client: "Заказчик",
              data : "Данные",
              do : "Дата заказа",
              status:['новый','временный резерв','резерв','бронь'],
              st : 'Статус'
            },
            stat:{
              title:'Источники заказов',
              source:['Источник','Процент','Количество'],
              country:"Страны",
              th:['Страна','Процент','Количество'],
            }
          }
        },
        gr: {
          translation: {
            menu: [
              {"url": "/", "name": "Κρατήσεις"},
              {"url": "/fin", "name": "Οικονομικά"},
              {"url": "/pay", "name": "Πληρωμές"},
              {"url": "/load", "name": "Λήψεις"},
              {"url": "/hotel", "name": "Ξενοδοχείο"},
              {"url": "/stat", "name": "Στατιστικά"}
            ],
            "book": {
              "title": "Κρατήσεις",
              "bc": "Κρατήσεις",
              "client": "Πελάτης",
              "source": "Πηγή",
              "shifr": "Κωδικός",
              "night": "Διανυκτερεύσεις",
              "guest": "Επισκέπτες",
              "all": "Σύνολο",
              "nores": "Δεν βρέθηκαν αποτελέσματα",
              "create": "Δημιουργήθηκε"
            },
            "fin": {
                "title": "Οικονομικά",
                "pay": "Κόστος",
                "client": ["Πελάτης", "Κωδικός", "Ποσό"],
                "nores": "Δεν βρέθηκαν αποτελέσματα"
            },
            "hotel": {
                "title": "Ξενοδοχείο",
                "days": "ημέρες",
                "book": "κρατήσεις",
                "cat": "Κατηγορία"
            },
            "det": {
              "book" : "Booking",
              "title": "Πληροφορίες Παραγγελίας",
              "profit": "Ισοζύγιο",
              "avia": "Πτήση",
              "live": "Διαμονή",
              "serv": "Υπηρεσίες",
              "all": "Σύνολο",
              "dis": "Έκπτωση",
              "topay": "Προς Πληρωμή",
              "payed": "Πληρωμένο",
              "ret": "Επιστροφή",
              "total": "Ισοζύγιο",
              "room": "Δωμάτιο",
              "dz": "Ημερομηνία άφιξης",
              "dv": "Ημερομηνία αναχώρησης",
              "night": "Διανυκτερεύσεις",
              "source": "Πηγή",
              "reis": "Αριθμός",
              "da": "Ημερομηνία άφιξης",
              "dd": "Ημερομηνία επιστροφής",
              "road": "Διαδρομή",
              "serv": "Υπηρεσίες",
              "adult": "Ενήλικες",
              "name": "Όνομα",
              "age": "Ηλικία",
              "pas": "Διαβατήριο",
              "country": "Χώρα",
              "data": "Ημερομηνία",
              "child": "Παιδιά",
              "client": "Πελάτης",
              "phone": "Τηλέφωνο",
              "dreg": "Ημερομηνία εγγραφής",
              "was": "Ήταν στον ιστότοπο",
              "type": "Τύπος",
              "ip": "Χώρα IP"
            },
            "load": {
                "title": "Φόρτωση",
                "all_load": "Συνολική φόρτωση",
                "table": ["Κατηγορία", "Δωμάτια", "Ημέρες Ν/Β", "Φόρτωση"],
                "all_day": "Σύνολο",
                "days": "ημέρες",
                "nores": "Δεν βρέθηκαν αποτελέσματα"
            },
            "pay": {
              "title": "Πληρωμές",
              "else": "Υπόλοιπες πληρωμές",
              "table": ["Πελάτης", "Κωδικός", "Πληρωμένο", "Υπόλοιπο"],
              "all": "Σύνολο",
              "nores": "Δεν βρέθηκαν αποτελέσματα"
            },
            "filter": {
                "dz": "Ημερομηνία άφιξης",
                "dc": "Ημερομηνία δημιουργίας",
                "pz": "Περίοδος από",
                "pd": "Περίοδος έως",
                "use": "Εφαρμογή",
                "cat": "Κατηγορίες",
                "c": "Κατηγορία",
                "f": "Φίλτρο",
                "search": "Αναζήτηση",
                "group": "Ομάδα",
                "client": "Πελάτης",
                "data": "Δεδομένα",
                "do": "Ημερομηνία παραγγελίας",
                "status": ["νέο", "προσωρινή κράτηση", "κράτηση", "κρατημένο"],
                "st": "Κατάσταση"
            },
            "stat": {
              "title": "Πηγές Παραγγελιών",
              "source": ["Πηγή", "Ποσοστό", "Ποσότητα"],
              "country": "Χώρες",
              "th": ["Χώρα", "Ποσοστό", "Ποσότητα"]
            }
          },
        },
      },
});

export default i18n;