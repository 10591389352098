import "./Filter.scss";

import { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll"
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "./Modal";
import {BookDate} from "./Filter/BookDate";
import {CreateDate} from "./Filter/CreateDate";
import {Client} from "./Filter/Client";
import {Status} from "./Filter/Status";
import {Category} from "./Filter/Category";

import {GlobalContext} from "../auth/GlobalProvider";

import { clearFilterAction,delFilterAction } from "../store/filterList";
import { useTranslation } from "react-i18next";

const Filter = () =>{

    const {t} = useTranslation();

    const filterMenu=[
        {label:t('filter.dc'),filter:<CreateDate/>},
        {label:t('filter.dz'),filter:<BookDate/>},
        {label:t('filter.client'),filter:<Client/>},
        {label:t('filter.cat'),filter:<Category/>},
        {label:t('filter.st'),filter:<Status/>}
    ];

    const {filter} = useSelector(state => state.filter);

    const dispatch = useDispatch();

    const clearFilter = () =>{
        dispatch(clearFilterAction());
    }

    const delFilter = (index) =>{
        dispatch(delFilterAction(index));
    }

    return (
        <div>
            <ScrollContainer className="overflow-none filter-block">
                <div className="filter">
                    {filterMenu.map((item,i)=>
                        <FilterItem item={item} key={i}/>
                    )}
                </div>
            </ScrollContainer>
            {filter.length?<div className="filter-set"> 
                <div>
                    <div className="filter-set-clear" onClick={()=>clearFilter()}></div>
                </div>
                <div>
                    {filter.map((value,index)=>
                    <div className="filter-set-item" key={index}>
                        <div>{value.name[0]}<div>{value.name[1]}</div></div>
                        <span onClick={()=>delFilter(index)}></span>
                    </div>)}
                </div>
            </div>:null}
        </div>
    )
}

const FilterItem = ({item}) =>{

    const [show, setShow] = useState(false);

    return (
        <div>
            {show?<Modal show={setShow}>
                <div>
                    <GlobalContext.Provider value={{show:setShow}}>
                        {item.filter}
                    </GlobalContext.Provider>
                </div>
            </Modal>:null}
            <div className="filter-item" onClick={()=>setShow(true)}>
                {item.label}
            </div>
        </div>
    )
}

export {Filter}