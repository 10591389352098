import {Fragment} from "react";
import {HotelBook} from "../HotelBook";

const toFullFormat = (date) => {
    //return date.toISOString().split('T')[0];
    let d = date.getDate();
    let m = ('0'+(date.getMonth()+1)).slice(-2)
    let y = date.getFullYear();
    return `${y}-${m}-${d}`;
}

const weekend = [0,6];

const HotelApart = ({data,dates,hideType}) =>{

    return(
        data.apart.map((apart,key)=>(
            <Fragment key={key}>
                <div className="hotel-main-type" onClick={()=>hideType(apart.id)}></div>
                <div className={`hotel-main-block ${apart.hide?'_hide':''}`}>
                    {
                        // number
                        apart.number.map((number,key)=>{
                            let allNumberBook = data?.book.filter(item => item.number === number.id);
                            return (
                                <div key={key} className="hotel-main-row">
                                    {
                                        // days
                                        dates.map((day,key)=>{
                                            const date = toFullFormat(day);
                                            const bookFilter = allNumberBook.filter(item => item.date === date);
                                            const dayNum = day.getDay();
                                            return <div key={key} className={`hotel-main-cell ${weekend.includes(dayNum) ? '_weekend' : ''}`} date={date}>
                                                {bookFilter.map((book,key) => <HotelBook book = {book} key = {key}/>)}
                                            </div>
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </Fragment>
        ))
    )
}

export {HotelApart}