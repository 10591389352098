// left cart modal
//const action = {type:''}

//localStorage.removeItem("like");

// local list
const filter = localStorage.getItem("filter") !== null 
  ? JSON.parse(localStorage.getItem("filter"))
  : [];

// const def=[
//   {type:1,name:'Дата заезда 13.03.2023',value:['2023-13-03','2023-13-03']},
//?   //{type:1,name:'',value:[fromData,toData]}
  
//   {type:2,name:'Дата заказа 13.03.2023',value:['2023-13-03','2023-13-03']},
//?  //{type:2,name:'',value:createData}

//   {type:3,user:0,name:'Заказчик Стешин Виктор',value:73},
//?   //{type:3,user:1,name:'',value:userId}

//   {type:3,user:1,name:'Группы "Заказчик,Турагент"',value:[19,20]},
//?   //{type:3,user:2,name:'',value:[typeId]}

//   {type:4,name:'Категория "Suite"',value:[14]},
//?   //{type:4,name:'',value:typeId}

//   {type:5,name:'Статус "новый"',value:[0]},
//?   //{type:5,name:'',value:status}
// ];

const defState={
    filter:filter
  }
  
  const CLEAR_FILTER = "CLEAR_FILTER";
  const ADD_FILTER = "ADD_FILTER";
  const DEL_FILTER = "DEL_FILTER";
  
  export const filterList = (state = defState,action) => {
    switch(action.type){
      // add
      case ADD_FILTER:
        var newFilter=state.filter;

        switch (action.filter.type){
          // book
          case 1:newFilter = [...newFilter,action.filter];break;
          // create
          case 2:newFilter = [...newFilter,action.filter];break;
          // client
          case 3:{
            switch(action.filter.user){
              case 0:newFilter = [...newFilter,action.filter];break;
              case 1:{
                newFilter = newFilter.filter(item => item.type!==3 || item.user!==1);
                newFilter = [...newFilter,action.filter];
              }break;
            }
          }break;
          // apart type
          case 4:{
            newFilter = newFilter.filter(item => item.type!=4);
            newFilter = [...newFilter,action.filter];
          }break;
          // status
          case 5:{
            newFilter = newFilter.filter(item => item.type!=5);
            newFilter = [...newFilter,action.filter];
          }break;
        }
        // if(newList.indexOf(action.id) === -1){
        //   newList = [...newList,action.id];
        // }else{
        //   newList = newList.filter(item => item !== action.id);
        // }

        localStorage.setItem("filter",JSON.stringify(newFilter));
        return {...state,filter:newFilter}
        
        // del
        case DEL_FILTER:
          var newFilter=state.filter.filter((value,key)=>action.index !== key);
          localStorage.setItem("filter",JSON.stringify(newFilter));
          return {...state,filter:newFilter}

        // clear
        case CLEAR_FILTER:
          localStorage.setItem("filter",JSON.stringify([]));
          return {...state,filter:[]}

      default:
        return state
    }
  
  }
  
  export const clearFilterAction = () => ({type:CLEAR_FILTER});
  export const addFilterAction = (filter) => ({type:ADD_FILTER,filter:filter});
  export const delFilterAction = (index) => ({type:DEL_FILTER,index:index});