import { useContext, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { addFilterAction } from "../../store/filterList";

import useAxios from  "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

import {Button,InputLabel,MenuItem,FormControl,Select} from '@mui/material';

import { GlobalContext } from "../../auth/GlobalProvider";
import { Loading } from '../Loading';
import { useTranslation } from 'react-i18next';

const Category = () =>{

    const {t} = useTranslation();

    const [cat,setCat] = useState(null);

    const {user} = useLogin();
    const axios=useAxios(user);

    // redux
    const dispatch = useDispatch();
    const {filter} = useSelector(state => state.filter);
    const def = filter.filter(item => item.type === 4)?.[0]?.value || [];

    const [value,setValue] = useState(def);

    // context
    const {show} = useContext(GlobalContext);

    useEffect(()=>{
        axios.get('/admin/cat')
        .then(({data})=>setCat(data.res))
    },[]);

    const setFilter = () => {
        if(value.length){
            const f=cat.filter(item=>value.includes(item.id));
            const text = f.map(item=>item.name).join(', ');
            const filter = {type:4,name:[t('filter.cat'),text],value:value};
            dispatch(addFilterAction(filter));
        }else{
           // clear type 5 
        }
        show(state=>!state);
    }

    return (
        <div className="filter-form">
            {cat?
            <>
            <div>
                <FormControl sx={{width:'100%'}}>
                    <InputLabel id="demo-simple-select-label">{t('filter.c')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        multiple
                        label={t('filter.c')}
                        onChange={e=>setValue(e.target.value)}
                    >
                        {cat.map((item)=>
                            <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Button size="large" variant="contained" sx={{ width: 1 }} onClick={()=>setFilter()}>{t('filter.use')}</Button>
            </div></>:<Loading/>}
        </div>
    )
}

export {Category}