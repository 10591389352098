import dayjs from "dayjs";
import "dayjs/locale/ru";

import { useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, TextField } from "@mui/material";
import { ClientSearch } from "./ClientSearch";
import { ClientGroup } from "./ClientGroup";
import { useTranslation } from "react-i18next";

const Client = () => {

  const {t} = useTranslation();

  const sx = { width: "100%" };

  const [type, setType] = useState(0);

  return (
    <div className="filter-form">
      <div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{t('filter.f')}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label={t('filter.f')}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value={0}>{t('filter.search')}</MenuItem>
            <MenuItem value={1}>{t('filter.group')}</MenuItem>
          </Select>
        </FormControl>
      </div>
      { 
        {
          0:<ClientSearch t={t}/>,
          1:<ClientGroup t={t}/>
        }[type]
      }
    </div>
  );
};

export { Client };
