
import './NotFound.scss';
const NotFound = () => {
    return (
        <div className="not-found">
            <div className="not-found-flex">
                <div className="not-found-404">404</div>
                <div className="not-found-text"><span>Ooopps:(</span> No page!</div>
            </div>
        </div>
    )
}

export {NotFound}