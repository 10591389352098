import { useContext, useState } from "react";

import moment from "moment";
import "moment/locale/ru";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { GlobalContext } from "../../../auth/GlobalProvider";

import { Button } from "@mui/material";

const HotelData = ({search,setSearch}) => {

  const sx = { width: "100%" };

  const [data, setValue] = useState({
    from: moment(search.data.from),
    to: moment(search.data.to)
  });

  const { show } = useContext(GlobalContext);

  const addFilter = () => {
    setSearch({...search,
      data:{
          from: new Date(data.from),
          to: new Date(data.to)
      }
    });
    show(state => !state);
  };

  return (
    <div className="filter-form">
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
        <div>
          <DatePicker
            views={["year", "month", "day"]}
            label="Период c"
            value={data.from}
            sx={sx}
            onChange={(value) => setValue({ ...data, from: value })}
          />
        </div>
        <div>
          <DatePicker
            views={["year", "month", "day"]}
            label="Период до"
            value={data.to}
            sx={sx}
            onChange={(value) => setValue({ ...data, to: value })}
          />

          {/* <SingleInputDateRangeField label="Период" sx={sx}/> */}
        </div>
      </LocalizationProvider>
      <div>
        <Button
          size="large"
          variant="contained"
          sx={{ width: 1 }}
          onClick={() => addFilter()}
        >
          Применить
        </Button>
      </div>
    </div>
  );
};

export { HotelData };
