import {Routes, Route} from 'react-router-dom';

import { Content } from '../app/Content';
import { NotFound } from '../app/pages/NotFound';
import { Book } from '../app/pages/Book';
import { Fin } from '../app/pages/Fin';
import { Pay } from '../app/pages/Pay';
import { Load } from './pages/Load';
import { Stat } from './pages/Stat';
import { Hotel } from './pages/Hotel';

const Account = () =>{

    return (
        <Routes>
            <Route path="/" element ={<Content/>}>
                <Route index element={<Book/>}/>
                <Route path="fin" element={<Fin/>}/>
                <Route path="pay" element={<Pay/>}/>
                <Route path="load" element={<Load/>}/>
                <Route path="hotel" element={<Hotel/>}/>
                <Route path="stat" element={<Stat/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        </Routes>
    )
}

export {Account}