import axios from 'axios';

const useAxios = (user) => {

    const config={
        baseURL:`${process.env.REACT_APP_DOMAIN}/v1`,
        headers:
            {
                'Authorization':`Bearer ${user?user.kod:''}`,
                //'Access-Control-Allow-Credentials':true,
                'Content-Type' : 'multipart/form-data'
            }
    };

    const instance = axios.create(config);

    // instance.interceptors.response.use((response) => {
    //     return response;
    // }, (error) => {
    //         if (error.response.status === 401) {
    //             signOut();
    //             return window.location.href = '/'
    //         }
    //     return Promise.reject(error);
    // });

    return instance;

}

export default useAxios;


// axios.defaults.baseURL = process.env.REACT_APP_API;
// 1?axios.defaults.headers.common['Authorization'] =  `Bearer ${process.env.REACT_APP_TEST_TOKEN}`:'';