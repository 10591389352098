import { useContext, useEffect, useState } from 'react';

import { GlobalContext } from "../../../auth/GlobalProvider";
import { Loading } from "../../../components/Loading";

import useAxios from  "../../../hook/useAxios";
import { useLogin } from "../../../hook/useLogin";

import {Button,InputLabel,MenuItem,FormControl,Select} from '@mui/material';

const HotelType = ({ search, setSearch }) => {
  const sx = { width: "100%" };
  const { show } = useContext(GlobalContext);

  const [cat, setCat] = useState(null);

  const { user } = useLogin();
  const axios = useAxios(user);

  const [value, setValue] = useState(search.type);

  useEffect(() => {
    axios.get("/admin/cat").then(({ data }) => setCat(data.res));
  }, []);

  const setFilter = () => {
    setSearch({ ...search, type: value });
    show((state) => !state);
  };

  return (
    <div className="filter-form">
       {cat?
        <><div>
            <FormControl sx={{width:'100%'}}>
                <InputLabel id="demo-simple-select-label">Категория</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    multiple
                    label="Категория"
                    onChange={e=>setValue(e.target.value)}
                >
                    {cat.map((item)=>
                        <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
        <div>
            <Button size="large" variant="contained" sx={{ width: 1 }} onClick={()=>setFilter()}>Применить</Button>
        </div></>:<Loading/>}
    </div>
  );
};

export { HotelType };
