import "../Page.scss";

import { useEffect, useState } from "react";
import { BronDet } from "../../components/BronDet";
import { Filter } from "../../components/Filter";

import ScrollContainer from "react-indiana-drag-scroll"

import { Loading } from "../../components/Loading";
import { useSelector } from "react-redux";

import useAxios from  "../../hook/useAxios";
import { useLogin } from "../../hook/useLogin";

import { Message } from "../../components/Message";
import { useTranslation } from "react-i18next";

const Book = () =>{

    const {i18n,t } = useTranslation();
    const lang = i18n.language;

    const [data,setData] = useState(null);

    const {user} = useLogin();
    const axios=useAxios(user);

    const filter = useSelector(state => state.filter);
    const [sort,setSort] = useState(false);

    useEffect(()=>{
        axios.get('/admin/book',{params:{...filter,lang:lang}})
        .then(({data})=>setData(data.res))
    },[filter,lang]);

    const sortData = (data) =>{
        return data.sort(function(a,b){
            return sort 
            ? new Date(b.created.data) - new Date(a.created.data)
            : new Date(a.created.data) - new Date(b.created.data);
        });
    }

    useEffect(()=>{
        if(data){
            data.list = sortData(data.list);
            setData({...data});
        }
    },[sort]);

    return (
        <div className="content-table">
            <div className="title">{t('book.title')}</div>
            <div><Filter/></div>

            {data
            ?data.list?
                <div className="content-info">

                    <div className="page-graf-block">
                        <div className="page-graf">
                            <div>{data.list.length}</div>
                            <div>{t('book.bc')}</div>
                        </div>
                    </div>

                    <ScrollContainer vertical={true} horizontal={true} className="content-res">
                        <table width="100%" className="table">
                            <thead>
                                <tr>
                                    <th>{t('book.client')}</th>
                                    <th onClick={()=>setSort(!sort)} className="_sort">{t('book.create')}</th>
                                    <th>{t('book.source')}</th>
                                    <th>{t('book.shifr')}</th>
                                    <th>{t('book.night')}</th>
                                    <th>{t('book.guest')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.list.map((item,key)=><Item key={key} item={item}/>)}
                            </tbody>
                            <tfoot className="table-total">
                                <tr>
                                    <td colSpan={4}>{t('book.all')}</td>
                                    <td>{data.total.night}</td>
                                    <td>{data.total.guest}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </ScrollContainer>

                </div>
                :<Message mes={t('book.nores')}/>
            :<Loading/>}

        </div>
    )
}

const Item = ({item}) =>{

    const [show, setShow] = useState(false);

    return (
        <>
            <tr className="table-row">
                <td className="table-text-overflow">
                    <div>{item.user.name}</div>
                    <div className="page-status">{item.user.type}</div>
                </td>
                <td>
                    <div>{item.source}</div>
                    <div className="page-status">{item.created.name}</div>
                </td>
                <td>
                    <div>{item.source_type}</div>
                </td>
                <td>
                    <div className={`href _inline page-shifr ${item.avia?'_avia':''}`} onClick={e=>{e.stopPropagation();setShow(true)}}>
                        {item.shifr}
                    </div>
                    <div className="page-status">{item.status.name}</div>
                </td>
                <td>{item.night}</td>
                <td>
                    <div>{item.guest.name}</div>
                    <div className="page-status">{item.guest.country}</div>
                </td>
            </tr>
            {show?<BronDet setShow={setShow} id={item.id}/>:''}
        </>
    )
    
}

export {Book}