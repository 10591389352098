import "./Modal.scss";

import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ClickAwayListener } from "@mui/base";

const Modal = ({ children, show }) => {
  // animation
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
  }, []);

  // late close after animation
  const lateClose = () => {
    setActive(false);
    setTimeout(() => {
      show(false);
    }, 500);
  };

  return ReactDOM.createPortal(
    <div className={`modal-center ${active ? "active" : ""}`}>
      <div className="modal-center-block">
        {/* <ClickAwayListener
          onClickAway={() => lateClose()}
        > */}
          <div className="modal-center-content">
            <div
              className="modal-center-close"
              onClick={() => lateClose()}
            ></div>
            {children}
          </div>
        {/* </ClickAwayListener> */}
      </div>
    </div>,
    document.getElementById("modal")
  );
};

export { Modal };
