import "./Top.scss";

import { useLogin } from "../hook/useLogin";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../lang.js";

import { Menu } from "./Menu";

const Top = () =>{

    const { i18n, t } = useTranslation();

    const onClick = (lang) => {
        i18n.changeLanguage(lang);
    };

    const lang = i18n.language ? i18n.language : "en";

    const {signOut} = useLogin();

    return (
        <div className="top">
            <div className="block-small">
                <div className="top-flex">
                    <div className="title-middle">Bareti butique hotel</div>
                    <div className="top-flex">
                        <div className="top-lang">
                        {LANGUAGES.map(({ code, label }) => (
                            <div 
                                key={code}
                                onClick={()=>onClick(code)}
                                className={`top-lang-item ${lang === code ? 'active' : ''}`}
                                >
                                    {label}
                                </div>
                        ))}
                        </div>
                        <div className="href icons _exit" onClick={()=>signOut()}></div>
                    </div>
                </div>
            </div>
            <div>
                <Menu/>
            </div>
        </div>
    )
}

export {Top}