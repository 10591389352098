import {createContext,useState} from "react";
import {useNavigate} from 'react-router-dom';

export const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {

    const navigate = useNavigate();

    // config state
    const [config,setConfig]=useState();

    // user info from localStorage
    const [user,setUser] = useState(
        localStorage.getItem('user')
        ?JSON.parse(localStorage.getItem('user'))
        :null
    );

    // axios
    //const axios = useAxios(user);

    //const dispatch = useDispatch();

    // get config for context
    // useEffect(()=>{
    //     axios.get('/site/config')
    //     .then(res=>res.data)
    //     .then(data=>{
    //         setConfig(data.res);
    //         dispatch(setCartDis(data.res.discount))
    //     });
    // },[]);

    const signIn = (user,url) => {
        // set user
        localStorage.setItem('user',JSON.stringify(user));
        setUser(user);
        // return to locate page
        if(url){
            navigate(url,{replace:true})
        }
    }

    const signOut = (url) => {
        // clear user
        localStorage.removeItem('user');
        setUser(null);
        // return to locate page
        if(url){
            navigate(url,{replace:true})
        }
    }

    const value = {
        user,
        setUser,
        signIn,
        signOut,
        
        config
    }

    return <AuthContext.Provider 
        value={value}> 
        {children}
    </AuthContext.Provider>
}