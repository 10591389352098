import "./Stat.scss";
import "../Page.scss";

import { useEffect, useState } from "react";
import { useLogin } from "../../hook/useLogin";
import useAxios from "../../hook/useAxios";

import ScrollContainer from "react-indiana-drag-scroll"
import { Loading } from "../../components/Loading";

import { GlobalContext } from "../../auth/GlobalProvider";

import { Modal } from "../../components/Modal";
import { HotelData } from "./Hotel/HotelData";

import { useTranslation } from "react-i18next";

const toFormat = (date) =>{
    console.log(date);
    let d = date.getDate();
    let m = ('0'+(date.getMonth()+1)).slice(-2)
    return `${d}.${m}`;
}

const Stat = () =>{

    const {t,i18n} = useTranslation();
    const lang = i18n.language;

    const [data,setData] = useState(null);

    const {user} = useLogin();
    const axios=useAxios(user);

    const [search, setSearch] = useState({
        data: {
            from: new Date(new Date().getFullYear(), 0, 1),
            to: new Date(new Date().getFullYear(), 11, 31)
        }
    });

    useEffect(()=>{
        axios.get('/admin/stat',{params:{...search,lang:lang}})
        .then(({data})=>setData(data.res))
    },[search,lang]);

    return (
        data?
        <div style={{overflow:"auto",paddingBottom:60}}>
            <div className="title">{t('stat.title')}</div>
            <div className="hotel-filter-left">
                <FilterData search={search} setSearch={setSearch} label={`${toFormat(search.data.from)}-${toFormat(search.data.to)}`}/>
            </div>
            <ScrollContainer vertical={true} horizontal={true}>
                <table width="100%" className="table">
                    <thead>
                        <tr>
                            {t('stat.source',{returnObjects:true}).map((v,i)=>
                                <th key={i}>{v}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.source.map((item,key)=><Source key={key} item={item}/>)}
                    </tbody>
                </table>
            </ScrollContainer>
            <br/><br/>
            <div className="title">{t('stat.country')}</div>
            <ScrollContainer vertical={true} horizontal={true}>
                <table width="100%" className="table">
                    <thead>
                        <tr>
                            {t('stat.th',{returnObjects:true}).map((v,i)=>
                                <th key={i}>{v}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.country.map((item,key)=><Country key={key} item={item}/>)}
                    </tbody>
                </table>
            </ScrollContainer>
        </div>
        :<Loading/>
    )
}

const Source = ({item}) => {
    return (
        <tr className="table-row">
            <td>{item.name}</td>
            <td>{item.proc} %</td>
            <td>{item.value}</td>
        </tr>
    )
}

const Country = ({item}) => {
    return (
        <tr className="table-row">
            <td>{item.country}</td>
            <td>{item.proc} %</td>
            <td>{item.value}</td>
        </tr>
    )
}

const FilterData = ({label,setSearch,search}) =>{

    const [show, setShow] = useState(false);

    return (
        <>
            {show?<Modal show={setShow}>
            <div>
                <GlobalContext.Provider value={{show:setShow}}>
                    <HotelData setSearch={setSearch} search={search}/>
                </GlobalContext.Provider>
            </div>
            </Modal>:null}
            <div>
                <div onClick={()=>setShow(true)}>
                    <div className="filter-item">{label}</div>
                </div>
            </div>
        </>
    )
}

export {Stat}