import "./Message.scss";

const Message = ({mes}) => {
    return (
        <div className="message-block">
            <div>{mes}</div>
        </div>
    )
}

export {Message}