import { Modal } from "./Modal";

import "./BronDet.scss";
import { Status } from "./Status";
import { useEffect, useState } from "react";
import useAxios from "../hook/useAxios";
import { useLogin } from "../hook/useLogin";
import { useTranslation } from "react-i18next";

const BronDet = ({setShow,id}) =>{

    const {t,i18n} = useTranslation();
    const lang = i18n.language;

    const [data,setData] = useState(null);

    const {user} = useLogin();
    const axios = useAxios(user);

    useEffect(()=>{
        axios.get('/admin/order',{params:{id:id,lang:lang}})
        .then(({data})=>{
            if(!data.error){
                setData(data.res)
            }
        })
    },[]);

    return (
        data?<Modal show={setShow}>
            <div className="bron-det">
                <div className="bron-det-top">
                    <div className="bron-det-name">
                        {t('det.title')}
                    </div>
                    <div className="bron-det-status">
                        <div><Status status={data.status}/></div>
                        <div>{data.created.data} {data.created.time}</div>
                    </div>
                </div>
                <div className="bron-det-content">

                    <div className="bron-det-block">

                        {/* pay balance */}
                        <div className="bron-det-info" style={{border:`3px solid ${data.balance.res>=0?'#15cdca':'#ff866e'}`}}>
                            <div className="bron-det-title">{t('det.profit')}</div>
                            <div className="bron-det-grid">
                                {data.balance.aprice?<><div>{t('det.avia')}</div><div>€ {data.balance.aprice}</div></>:''}
                                {data.balance.bprice?<><div>{t('det.live')}</div><div>€ {data.balance.bprice}</div></>:''}
                                {data.balance.sprice?<><div>{t('det.serv')}</div><div>€ {data.balance.sprice}</div></>:''}
                                <div className="bron-det-space"></div>
                                <div>{t('det.all')}</div><div>€ {data.balance.all_price}</div>
                                <div>{t('det.dis')}</div><div>€ {data.balance.discount}</div>
                                <div>{t('det.topay')}</div><div>€ {data.balance.price}</div>
                                <div className="bron-det-space"></div>
                                <div>{t('det.payed')}</div><div>€ {data.balance.deb}</div>
                                <div>{t('det.ret')}</div><div>€ {data.balance.kred}</div>
                                <div>{t('det.total')}</div><div>€ {data.balance.res}</div>
                            </div>
                        </div>
                        
                        {/* book */}
                        {data.bron?<div className="bron-det-info">
                            <div className="bron-det-title">{t("det.book")} {data.bron.shifr}</div>
                            <div className="bron-det-grid">
                                <div>{t('det.room')}</div><div>{data.bron.type} {data.bron.number}</div>
                                <div>{t('det.dz')}</div><div>{data.bron.data_from} <span className={data.bron.early?'bron-det-clock':''}>{data.bron.time_from}</span></div>
                                <div>{t('det.dv')}</div><div>{data.bron.data_to} <span>{data.bron.time_to}</span></div>
                                <div>{t('det.night')}</div><div>{data.bron.night}</div>
                                <div>{t('det.source')}</div><div>{data.source_type.type}</div>
                            </div>
                        </div>:''}

                        {/* avia */}
                        {data.avia?<div className="bron-det-info">
                            <div className="bron-det-title">{data.avia.title} {data.avia.shifr}</div>
                            <div className="bron-det-grid">
                                <div>{t('det.reis')}</div><div>{data.bron.type} {data.bron.number}</div>
                                <div>{t('det.da')}</div><div>{data.avia.data_from}</div>
                                {data.avia.data_to?<><div>{t('det.dd')}</div><div>{data.avia.data_to}</div></>:''}
                                <div>{t('det.road')}</div><div>${data.avia.route}</div>
                            </div>
                        </div>:''}

                        {/* services */}
                        {data.serivices?<div className="bron-det-info">
                            <div className="bron-det-title">{t('det.serv')}</div>
                                {data.serivices.map((item,key)=>
                                    <div className="bron-det-grid" key={key}>
                                        <div>{item.name}</div><div>{item.price}</div>
                                        <div>{item.info}</div>
                                    </div>
                                )}
                        </div>:""}

                        {/* adult */}
                        {data.adult?<div className="bron-det-info">
                            <div className="bron-det-title">{t('det.adult')}</div>
                                {data.adult.map((item,key)=>
                                    <div className="bron-det-grid" key={key}>
                                        <div>{t('det.name')}</div><div>{item.last_name} {item.name?item.name:'-'}</div>
                                        <div>{t('det.age')}</div><div>{item.birth?item.birth:'-'} {item.old}</div>
                                        <div>{t('det.pas')}</div><div>{item.p_number}</div>
                                        <div>{t('det.country')}</div><div>{item.country?item.country:'-'}</div>
                                        <div>{t('det.data')}</div><div>{item.p_data}</div>
                                    </div>
                                )}
                        </div>:""}

                        {/* child */}
                        {data.child?<div className="bron-det-info">
                            <div className="bron-det-title">{t('det.child')}</div>
                                {data.child.map((item,key)=>
                                    <div className="bron-det-grid" key={key}>
                                        <div>{t('det.name')}</div><div>{item.last_name} {item.name?item.name:'-'}</div>
                                        <div>{t('det.age')}</div><div>{item.birth?item.birth:'-'} {item.old}</div>
                                        <div>{t('det.pas')}</div><div>{item.p_number}</div>
                                        <div>{t('det.country')}</div><div>{item.country?item.country:'-'}</div>
                                        <div>{t('det.data')}</div><div>{item.p_data}</div>
                                    </div>
                                )}
                        </div>:""}

                        {/* client */}
                        {data.client?<div className="bron-det-info">
                            <div className="bron-det-title">{t('det.name')}</div>
                                <div className="bron-det-grid">
                                    <div>{t('det.name')}</div><div>{data.client.name?data.client.name:'-'}</div>
                                    <div>{t('det.phone')}</div><div>{data.client.phone?data.client.phone:'-'}</div>
                                    <div>Email</div><div style={{overflowWrap : 'anywhere'}}>{data.client.email?data.client.email:'-'}</div>
                                    <div className="bron-det-space"></div>
                                    <div>{t('det.dreg')}</div><div>{data.client.reg}</div>
                                    <div>{t('det.was')}</div><div>{data.client.enter}</div>
                                    <div>{t('det.type')}</div><div>{data.client.type?data.client.type:'-'}</div>
                                    <div>{t('det.ip')}</div><div>{data.country?data.country:'-'}</div>
                                </div>
                        </div>:""}

                    </div>
                </div>
            </div>
        </Modal>:''
    )
}

export {BronDet}