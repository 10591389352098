import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import  useAxios  from "../hook/useAxios";
import { Errors } from "../components/Errors";
import { useLogin } from "../hook/useLogin";

import "./Enter.scss";

const Enter = ({setPage}) =>{

    // hooks
    const [showPas,setPas]=useState(true);
    const {user,signIn} = useLogin();
    const axios=useAxios(user);

    // err message
    const err={
        req: "Поле обов\'язкове до заповнення",
        pas: "Довжина пароля мін. 6 символів",
        email: "E-Mail невірного формату",
    }

    // form
    const {
        register, 
        handleSubmit,
        control,
        setError,
        formState: {errors},
    } = useForm();

    const showError = (error) => {
        for (const [key,val] of Object.entries(error)){
            setError(key,{type:"required",message:val})
        }
    }

    // enter user
    const onSubmit = data => {

        // console.log(data);
        // return false;

        axios.get('/site/enter',{params:data})
        .then(res=>res.data)
        .then(data => {
            // no user, user problem
            if(data.error){
                showError(data.error);
            }else{
                signIn(data.res);
            }
        })
    }

    // Prompt
    const [deferredPrompt,setPrompt] = useState(null);

    useEffect(()=>{
        // get PWA instal obj
        window.addEventListener('beforeinstallprompt', (e) => {
            //alert(e);
            setPrompt(e);
        });
        // if instaled
        window.addEventListener('appinstalled', () => {
            setPrompt(null);
        });
    },[])

    const Instal = async () =>{
        
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        
        //if user install dismissed accepted
        if(outcome === 'accepted'){
            deferredPrompt = null;
        }
    }

    const emailRegex=/^[^\s]+@[^\s@]+(\.[^\s@]+){1,}$/;

    return (
        <div className="enter-form">
            <div className="enter-form-block">
                <div className="enter-form-top">Вход в систему</div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                    <div className="enter-form-fields">
                        <div>
                            <input 
                                {...register("email",{
                                    required:err.req,
                                    validate:value=>{
                                        return emailRegex.test(value) || value=='admin' || err.email
                                    }
                                })}
                                className="inputs"
                                placeholder="E-mail"
                                autoComplete="off"/>
                            <Errors error={errors?.email?.message}/>
                        </div>
                        <div>
                            <div className="account-pas">
                                <input 
                                    {...register("pas",{
                                        required:err.req,
                                        // minLength: {
                                        //     value: 6,
                                        //     message: err.pas
                                        // }
                                    })}
                                    className="inputs" 
                                    type={showPas?'password':'text'}
                                    placeholder="Пароль"/>
                                <div className="account-pas-show" onClick={()=>setPas(!showPas)}></div>
                            </div>
                            <Errors error={errors?.pas?.message}/>
                        </div>
                        <div><button type="submit" className="button _black _width _center">Вход</button></div>
                    </div>

                </form>
                </div>
            </div>
            <div>
                {deferredPrompt?<div className="enter-logo" onClick={()=>Instal()}>
                    Instal App
                </div>:''}
            </div>
            <div className="enter-copy">&copy; Webmaker Studio App</div>
        </div>
    )
}

export {Enter};