import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import useAxios from '../hook/useAxios';
import {useLogin} from '../hook/useLogin';

const RequireAuth = ({children}) => {
    
    // from url
    const {user,setUser} = useLogin();
    const axios = useAxios(user);

    //if not auth return relink component
    useEffect(()=>{
        if(user){
            // update user account
            axios.get('/admin/user/')
            .then(res=>res.data)
            .then(data=>{
                // no user
                if(!data.res){
                    localStorage.removeItem('user');
                    setUser(null);
                    return <Navigate to={'/'}/>;
                }
            }).catch(function (error) {
                alert(error);
            })
        }
    },[]);

    // else return cart
    return children;
} 

export {RequireAuth}
