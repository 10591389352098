import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./Menu.scss";

import { useTranslation } from "react-i18next";
import ScrollContainer from 'react-indiana-drag-scroll'

const Menu = () =>{

    const { t } = useTranslation();

    const navigate = useNavigate();

    const {pathname} = useLocation();

    const menu = t('menu',{ returnObjects: true });

    const [active,setActive] = useState(menu.map(item=>item.url).indexOf(pathname));

    const go = (item,i) =>{
        navigate(item.url);
        setActive(i);
    }

    return (
        <ScrollContainer className="menu-block overflow-none">
            <div className="menu">
                {
                    menu.map((item,i)=>
                    <div className={`menu-item href ${active === i?'active':''}`} key={i} onClick={()=>go(item,i)}>
                        {item.name}
                    </div>
                )}
            </div>
        </ScrollContainer>
    )
}

export {Menu}